
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import { mobileRegExp, emailRegExp } from '@/utils/reg'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { getMBankAuth, setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'

@Component({
  name: 'maidanba',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
  // @Provide() public ischeck: boolean = false
  // @Provide() public code: boolean = false
  async created () {
    const code = getCurrentQuery('code')
    const url = getCurrentQuery('url')
    const pre = location.href.indexOf('pre-passport.benlai.com') > -1
    const uri = pre ? 'https://pre-passport.benlai.com/' : 'https://passport.benlai.com/'
    if (url) {
      location.href = `https://creditcardapp.bankcomm.com/oauth2/authorize?client_id=maidanba_to_benlaish&response_type=code&redirect_uri=` + uri + 'partner?redirect=' + url + '&code=' + code + '&targetCode=' + this.targetCode
    } else {
      location.href = `https://creditcardapp.bankcomm.com/oauth2/authorize?client_id=maidanba_to_benlaish&response_type=code&redirect_uri=` + 'https://passport.benlai.com/partner?redirect=' + encodeURI(`https://m.benlai.com`) + '&code=' + code + '&source=2' + '&targetCode=' + this.targetCode
    }
  }

  // @Emit()
  // public select () {
  //   this.ischeck = !this.ischeck
  // }

  // @Emit()
  // public next () {
  //   const code = getCurrentQuery('code')
  //   if (!this.ischeck) {
  //     this.$toasted.show('勾选下方同意后，才可去购物哦~')
  //     return false
  //   }
  //   setMBankAuth()
  //   location.href = 'https://passport.benlai.com/partner?redirect=' + encodeURI(`https://m.benlai.com`) + '&code=' + code + '&source=2'
  // }
}
